import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import PortfolioType from '../components/PortfolioType'

const Wrapper = styled.section`
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
`

const Title = styled.h1`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const HeroPortfolio = props => (
  <Wrapper className="portfolio-image-wrapper" height={props.height}>
    <Img className="portfolio-image" fluid={props.image.fluid} backgroundColor={'#eeeeee'} />
    <div className="portfolio-logo">
      <Img className="portfolio_image-logo"
        fixed={props.logo.fixed}
        alt=""
      />
    </div>
    <Title className="portfolio-title">
      {props.title}
    </Title>
    <PortfolioType articleType={props.articleType} projectType={props.projectType}></PortfolioType>
  </Wrapper>
)

export default HeroPortfolio

import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import LBNavigation from '../components/LBNavigation'
import HeroPortfolio from '../components/HeroPortfolio'
import PortfolioBlocks from '../components/PortfolioBlocks'
import ParallaxImage from '../components/ParallaxImage'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Img from 'gatsby-image'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import { Parallax } from 'react-parallax';

const PortfolioTemplate = ({ data, pageContext }) => {
  useEffect(() => {
    document.body.classList.add("overflow-auto");
    // will unmount
    return () => {
      document.body.classList.remove("overflow-auto");
    }
  }, [])
  const {
    title,
    slug,
    heroImage,
    logo,
    introText,
    articleType,
    projectType,
    lateralStripesColor,
    publishDate,
    myRole,
    bodyLong,
    htmlcss,
    parallaxImage,
    blocks
  } = data.contentfulPortfolio

  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext
  const { siteUrl } = useSiteMetadata()
  const articleUrlEncoded = encodeURIComponent(siteUrl+"/"+slug)
  const articleTitleEncoded = encodeURIComponent(title)

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Parallax 
      strength={500}
      renderLayer={(percentage) => (
        <div className="parallax-elements">
          {parallaxImage.map((parallax) => (
            <ParallaxImage
              name={parallax.name} 
              velocity={parallax.velocity}
              position={parallax.horizontalAlignment} 
              topPosition={parallax.topPosition} 
              blur={parallax.blur} 
              image={parallax.image}
              imageWidth={parallax.imageWidth}
              imageHeight={parallax.imageHeight}
              percentage={percentage} />
          ))}
          </div>
      )}
       >
      <div className="portfolio-container">
        <LBNavigation isHome={false}/>
        <Layout pageClass="portfolio-page" lateralStripes={lateralStripesColor !== null || undefined ? lateralStripesColor : ''}>
          <SEO
            title={title}
            image={ogImage}
          />
          <HeroPortfolio title={title} logo={logo} image={heroImage} author="Luca Belli" date={publishDate} height={'50vh'} articleType={articleType} projectType={projectType} />
          <Container padding={'0 1.5em 0'} maxWidth={'1300px'}>
            <h2 className="portfolio-intro-text">{introText.introText}</h2>
          </Container>
          <section className="portfolio-my-role">
            <div className="my-role-label">My role</div> 
            <div className="my-role-values">{myRole}</div>
          </section>
          {blocks.map((block) => (
          <PortfolioBlocks 
            cssClass={block.cssClass}
            bgImage={block.bgImage !== null ? "url(" + block.bgImage.file.url + ")" : "none"}
            bgColor={block.bgColor !== null ? block.bgColor : "initial"}
            fontColor={block.fontColor !== null ? block.fontColor : "initial"}
            content={block.text.childMarkdownRemark.html} />
          ))}
          { next !== null || undefined ?
          <section className="portfolio-next-project">
            <div className="portfolio-next-project-wrapper">
              <div className="next-project next-project-label">Prossimo progetto?</div>
              <div className="next-project next-project-title">"{next.title}"</div> 
              <div className="next-project next-project-button">
                <Link to={"/portfolio/" + next.slug} className="button-vedi">vedi</Link>
              </div>
            </div>
          </section>
          : null }
        </Layout>
        <div id="html-css" dangerouslySetInnerHTML={{ __html: htmlcss.childMarkdownRemark.html}}></div>
      </div>
    </Parallax>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPortfolio(slug: { eq: $slug }) {
      title
      slug
      articleType
      projectType
      lateralStripesColor
      publishDate(formatString: "DD MMMM YYYY", locale: "it")
      myRole
      introText {
        introText
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      logo {
        title
        fixed(width: 100, quality: 80) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      blocks {
        id
        cssClass
        bgColor
        fontColor
        bgImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
          }
        }
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      bodyLong {
        childMarkdownRemark {
          html
        }
      }
      htmlcss {
        childMarkdownRemark {
          html
        }
      }
      parallaxImage {
        blur
        horizontalAlignment
        name
        velocity
        topPosition
        image {
          title
          fixed(quality: 80) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
        imageHeight
        imageWidth
      }
    }
  }
`

export default PortfolioTemplate

import React from 'react'
import Container from '../components/Container'

class PortfolioBlocks extends React.Component {

    render() {
        return (
            <section className={"portfolio-blocks-" + this.props.cssClass} style={{backgroundImage: `${this.props.bgImage}`, backgroundColor: `${this.props.bgColor}`, backgroundSize: 'cover'}}>
                <Container maxWidth={'1300px'}>
                    <div className="portfolio-body text-styles-portfolio" dangerouslySetInnerHTML={{ __html: this.props.content }} style={{color: `${this.props.fontColor}`}}></div>
                </Container>
            </section>
        )
    }
}

export default PortfolioBlocks

import React from 'react'
import Img from 'gatsby-image'

class ParallaxImage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  stringToSlug(str) {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
  
    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return str;
}

  render() {
    return (
      <div
        className="parallax-image-wrapper"
        style={{
          position: "absolute",
          left: `${this.props.position === "left" ? "150px" : "auto"}`,
          right: `${this.props.position === "right" ? "-40px" : "auto"}`,
          top: (this.props.percentage * this.props.velocity) - this.props.topPosition,
          transform: "translate(-50%,-50%)",
          width: this.props.imageWidth,
          height: this.props.imageHeight,
          zIndex: 500,
          filter: "blur(" +this.props.blur+ "px)"
        }}
      >
        <Img className={`parallax-image px-img-` + this.stringToSlug(this.props.name)} fluid={this.props.image.fixed} backgroundColor={'#eeeeee'} style={{
          width: this.props.imageWidth,
          height: this.props.imageHeight
        }} />
      </div>
    )
  }
}

export default ParallaxImage
